import React from 'react';
import { ImageListItem, ImageListItemBar, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { css } from '@emotion/css';


function Comic({ comic, index, handleComicActive }) {

    const styles = {
        Image: css`
        maxHeight: 800px;
        &:hover {
            background-color: rgb(0,0,0);
            cursor: pointer;
        }
        `
    }

    const { Image } = styles;

    return (
        <div>
            <ImageListItem key={`${index}`} onClick={handleComicActive}>
                <img
                    src={comic.images[0] ?
                        'https'.concat(`${comic.images[0].path}.${comic.images[0].extension}?w=248&fit=crop&auto=format`.slice(4))
                        :
                        'https://i.pinimg.com/474x/c1/3f/d3/c13fd3dcdee4c6cb0c9afad78debd898.jpg'}
                    srcSet={comic.images[0] ?
                        'https'.concat(`${comic.images[0].path}.${comic.images[0].extension}?w=248&fit=crop&auto=format&dpr=2x`.slice(4))
                        :
                        'https://i.pinimg.com/474x/c1/3f/d3/c13fd3dcdee4c6cb0c9afad78debd898.jpg'}
                    alt={comic.title}
                    loading="lazy"
                    id={comic.id}
                    className={Image}
                />
                <ImageListItemBar
                    title={comic.title}
                    subtitle={comic.author}
                    actionIcon={
                        <IconButton
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            aria-label={`info about ${comic.title}`}
                        >
                            <InfoIcon />
                        </IconButton>
                    }
                />
            </ImageListItem>

        </div >
    );
}

export default Comic;