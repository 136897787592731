import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from './styles/ColorBoxStyles';
import { css } from '@emotion/css';
import chroma from "chroma-js";

function ColorBox({ background, name, paletteId, id, showingFullPalette }) {
  const localStyles = {
    ColorBox: css`
    height: ${showingFullPalette ? "25%" : "50%"};
       `,
    copyText: css`
    color: ${chroma(background).luminance() >= 0.6 ? "black" : "white"};
          `,
    colorName: css`
    color: ${chroma(background).luminance() <= 0.09 ? "white" : "rgba(0, 0, 0, 0.8)"};
          `,
    seeMore: css`
    color: ${chroma(background).luminance() >= 0.6 ? "rgba(0, 0, 0, 0.8)" : "white"};
          `,
    copyButton: css`
    color: ${chroma(background).luminance() >= 0.6 ? "rgba(0, 0, 0, 0.8)" : "white"};   
          `
  }


  const [copied, setCopied] = useState(false);

  const SetCopyState = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1500)
  }

  const { boxContent, ColorBox, colorName, copyOverlay, copyButton, copyMsg, copyText, seeMore, showOverlay, showMsg } = styles;

  return (
    <CopyToClipboard text={background} onCopy={SetCopyState}>
      <div className={`${ColorBox} ${localStyles.ColorBox}`} style={{ background }}>
        <div style={{ background }} className={`${copyOverlay} ${copied && showOverlay}`} />
        <div className={`${copyMsg} ${copied && showMsg}`}>
          <h1>Copied!</h1>
          <p className={`${copyText} ${localStyles.copyText}`}>{background}</p>
        </div>
        <div>
          <div className={boxContent}>
            <span className={`${colorName} ${localStyles.colorName}`}>{name}</span>
          </div>
          <button className={`${copyButton} ${localStyles.copyButton}`}>Copy</button>
        </div>
        {showingFullPalette && (
          <NavLink to={`/projects/color-app/palette/${paletteId}/${id}`} onClick={e => e.stopPropagation()}>
            <span className={`${seeMore} ${localStyles.seeMore}`}>MORE</span>
          </NavLink>
        )}

      </div>
    </CopyToClipboard>
  )
}

export default ColorBox;