import { css } from '@emotion/css';

const styles = {
  Palette: css`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 1260px) {
      overflow: scroll;
    }
    `,
  PaletteColors: css`
    height: 90%;
    `,
  goBack: css`
    background-color: black;
    height: 50%;
    width: 20%;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-bottom: -3.5px;
    a {
        width: 100px;
        height: 30px;
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 50%;
        margin-left: -50px;
        margin-top: -15px;
        text-align: center;
        outline: none;
        background: rgba(255, 255, 255, 0.3);
        font-size: 1rem;
        line-height: 30px;
        color: white;
        text-transform: uppercase;
        border: none;
        text-decoration: none;
        cursor: pointer;
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 50%;
    }
    @media (min-width: 768px) {
      width: 50%;
      height 25%;
    }
    @media (min-width: 1260px) {
      width: 20%;
      height 25%;
    }
    `
}

export default styles;