import { css } from '@emotion/css';

const styles = {
    PaletteFooterStyle: css`
        background-color: white;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-weight: bold;
              `,
    EmojiStyle: css`
        font-size: 1.5rem;
        margin: 1rem;
              `
}

export default styles;