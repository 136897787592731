import { NavLink } from 'react-router-dom';
import { MaterialUISwitch, AppBar, DrawerHeader, drawerWidth } from '../styles/NavDrawerStyles.js'
import { css } from '@emotion/css';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoIcon from '@mui/icons-material/Info';
import ApprovalIcon from '@mui/icons-material/Approval';
import SourceIcon from '@mui/icons-material/Source';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CommentIcon from '@mui/icons-material/Comment';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArticleIcon from '@mui/icons-material/Article';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import FormControlLabel from '@mui/material/FormControlLabel';



const categories = [
    {
        title: 'About Me',
        link: '/',
        icon: 'InfoIcon'
    },
    {
        title: 'Certifications',
        link: '/certifications',
        icon: 'ApprovalIcon'
    },
    {
        title: 'Projects',
        link: '/projects',
        icon: 'SourceIcon'
    },
    {
        title: 'Algorithms',
        link: '/algorithms',
        icon: 'AccountTreeIcon'
    },
    {
        title: 'Testimonials',
        link: '/testimonials',
        icon: 'CommentIcon'
    },
    {
        title: 'Blogs',
        link: '/blogs',
        icon: 'RSSFeedIcon'
    },
    {
        title: 'Contact',
        link: '/contact',
        icon: 'MailIcon'
    }
]


// Functions

// const handleDarkmode = () => {
//   setDarkmode(!darkmode);
// };



const AppNavigation = ({ darkmode, setDarkmode, theme, open, setOpen, styleTheme, setStyleTheme }) => {
    const styleThemeToggler = () => {
        styleTheme === 'dark' ? setStyleTheme('light') : setStyleTheme('dark')
        setDarkmode(!darkmode);
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const styles = {
        Navbar: css`
                display: flex;
                justify-content: space-between;
                background-color: ${darkmode ? '#141414' : 'white'};
            `,
        NavbarText: css`
                color: ${darkmode ? 'rgba(225,225,225,.9)' : 'rgb(86,89,90)'};
                transition: 0.3s;
            `,
        Icon: css`
                color: ${darkmode ? 'rgba(225,225,225,.9)' : 'rgb(86,89,90)'};
                margin-right: 8px;
                a {
                  text-decoration: none;
                  color: inherit;
                  cursor: inherit;
                }
                &:hover {
                  color: ${darkmode ? 'rgba(245,245,245,.9)' : 'rgb(66,68,70)'};
                  cursor: pointer;
                }
            `,
        DrawerMenu: css`
            ${open ?
                `background-color: ${darkmode ? '#141414' : 'white'}`
                :
                `background-color: ${darkmode ? '#141414' : 'white'}`
            };
            height: 0vh;
            `,
        AvatarImg: css`
                height: 5.5rem;
                width: 5.5rem;
                margin: 6px;
                border-radius: 50%;
            `,
        Char: css`
            font-size: 24px;
            a {
              text-decoration: none;
              color: inherit;
              cursor: inherit;
            }
            `
    }

    const { Navbar, NavbarText, Icon, DrawerMenu, AvatarImg } = styles;

    return <div><AppBar position="fixed" open={open}>
        <div className={Navbar}>
            <Toolbar>
                <IconButton
                    className={NavbarText}
                    color={darkmode ? "inherit" : "black"}
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    variant="h4"
                    noWrap
                    component="div"
                    className={NavbarText}
                >
                    <a href='https://www.facebook.com/brandon.coburn.31/'><FacebookIcon className={Icon} /></a>
                    <a href='https://www.linkedin.com/in/brandon-coburn-220b8724a/'><LinkedInIcon className={Icon} /></a>
                    <a href='https://github.com/BACoburn93'><GitHubIcon className={Icon} /></a>
                    <a href='https://docs.google.com/document/d/e/2PACX-1vQdQRymJ3dCs0AED2YUDKFWfcsrD61cjJ_cunzqH9f4OD5qRxTxV2R26pfjaxCc_S0WDbcyaCVc4VkY/pub'><ArticleIcon className={Icon} /></a>
                </Typography>
            </Toolbar>
            <FormControlLabel
                control={<MaterialUISwitch sx={{ m: 1 }} />}
                onChange={styleThemeToggler}
                label={darkmode ? "Dark Mode" : "Light Mode"}
                checked={darkmode}
                className={NavbarText}
            />
        </div>
    </AppBar>
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    backgroundColor: 'inherit'
                },
            }}
            variant="persistent"
            anchor="left"
            open={open}
            className={DrawerMenu}
        >
            <DrawerHeader>
                <img
                    alt="BrandonImg"
                    src="/images/brandon-coburn.jpg"
                    className={AvatarImg}
                />
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon className={NavbarText} /> : <ChevronRightIcon />}
                </IconButton>
            </DrawerHeader>
            <List>
                {categories.map((category, index) => (
                    <NavLink
                        to={category.link}
                        style={{ textDecoration: 'none' }}
                    >
                        {index === 0 && <Divider />}
                        <ListItem key={category.title} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {index === 0 && <InfoIcon className={NavbarText} />}
                                    {index === 1 && <ApprovalIcon className={NavbarText} />}
                                    {index === 2 && <SourceIcon className={NavbarText} />}
                                    {index === 3 && <AccountTreeIcon className={NavbarText} />}
                                    {index === 4 && <CommentIcon className={NavbarText} />}
                                    {index === 5 && <RssFeedIcon className={NavbarText} />}
                                    {index === 6 && <MailIcon className={NavbarText} />}
                                </ListItemIcon>
                                <ListItemText primary={category.title} className={NavbarText} />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                    </NavLink>
                ))}
            </List>
        </Drawer>
    </div>
}

export default AppNavigation;