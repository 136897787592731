import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function Certificate({ title, link, skills, Body, Text }) {
    return (
        <Card
            sx={{ maxWidth: 345, minWidth: 300 }}
            style={{ margin: '1.5rem' }}
        >
            <CardMedia
                component="img"
                alt="web dev certificate"
                height="250"
                src={link}
            />
            <CardContent className={Body}>
                <Typography gutterBottom variant="h5" component="div" className={Text}>
                    {title}
                </Typography>
                <Typography variant="body2" className={Text}>
                    {skills}
                </Typography>
            </CardContent>
        </Card>
    );
}