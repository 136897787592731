import React, { useState, useEffect } from 'react';
import { imageData } from './data';
import { ImageList, ImageListItem } from '@mui/material';

export default function AboutMe({ CardContainer, HeaderContainer, Header, Info, CardInfo }) {
    const [trait, setTrait] = useState('');
    const [traitNum, setTraitNum] = useState(0);

    useEffect(() => {
        const traits = ['A Full Stack Developer', 'A Creative Problem Solver', 'An Innovative Thinker', 'An Enthusiatic Learner', 'A Passionate Programmer', 'A Motivational Leader'];

        setTrait(traits[traitNum]);

        const interval = setInterval(() => {
            if (traitNum < traits.length - 1) {
                setTraitNum(traitNum + 1);
            }
            else {
                setTraitNum(0);
            }
            setTrait(traits[traitNum]);
        }, 5000);
        return () => clearInterval(interval);
    }, [traitNum]);

    return (
        <div className={CardContainer}>
            <div className={HeaderContainer}>
                <h1>Nice To Meet You, I'm Brandon...</h1>
                <h2 className={Info}><i className='animate-flicker'>{trait}</i></h2>
                <h3 className={CardInfo}>Hello, thank you for taking the time to view my Portfolio. Here, you will see some examples of my skills as a Website and Application Developer. I am working every day to improve my craft and hope to fulfill a lifetime making a positive difference in the world. Outside of coding, I enjoy many hobbies. I am a musician, a workout enthusiast, and a gamer with a huge love for all things Dungeons and Dragons. I love being active and making every day count. I hope to hear from you to discuss opportunities.</h3>
            </div>

            <ImageList variant="masonry" cols={3} gap={8}>
                {imageData.map((item) => (
                    <ImageListItem key={item.img}>
                        <img
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>

    )
}
