import React, { useState } from 'react'
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

function PaletteMetaForm({ handleSubmit, hideForm, stage, setStage, showEmojiPicker }) {
    const [newPaletteName, setNewPaletteName] = useState('');

    const handlePaletteNameChange = (e) => {
        setNewPaletteName(e.target.value);
    };

    const savePalette = (emoji) => {
        const newPalette = { paletteName: newPaletteName, emoji: emoji.native };
        handleSubmit(newPalette);
        setStage("");
    }

    return (
        <div>
            <Dialog open={stage === 'emoji'}>
                <DialogTitle>
                    Choose a Palette Emoji
                </DialogTitle>
                <Picker
                    data={data}
                    onEmojiSelect={savePalette}
                    onClickOutside={hideForm}
                />
                <Button onClick={hideForm}>Cancel</Button>
            </Dialog>
            <Dialog open={stage === "form"} onClose={hideForm}>
                <DialogTitle>Choose a Palette Name</DialogTitle>
                <ValidatorForm
                    onSubmit={showEmojiPicker}
                >
                    <DialogContent>
                        <DialogContentText>
                            Please enter a name for your new beautiful color palette. Make sure it's unique!
                        </DialogContentText>
                        <TextValidator
                            autoFocus
                            fullWidth
                            margin='normal'
                            label='Palette Name'
                            value={newPaletteName}
                            name='newPaletteName'
                            onChange={handlePaletteNameChange}
                            validators={['required', 'isPaletteNameUnique']}
                            errorMessages={['Enter Palette Name', 'Name already used']}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={hideForm}>Cancel</Button>
                        <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                        >
                            Save Palette
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </div>
    );
}

export default PaletteMetaForm;
