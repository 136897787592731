import React, { useState } from 'react';
import AlgorithmCard from './AlgorithmCard';
import { algorithmData, algorithmCategories } from './data';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export default function Algorithms({ darkmode, HeaderContainer, CardContainer, CardInfo, Cards, Body, Text, activeCategory, handleActiveCategory }) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={CardContainer}>
            <div className={HeaderContainer}>
                <h1>{'<'}Algorithms{'>'}</h1>
                <h3 className={CardInfo}>In this section, I will go over various Algorithms that I have solved in the past. If you click on one, a more in depth version of that algorithm will be revealed that will show both the actual code as well as a summary of my thought process when writing the function. You may also filter through them if you are looking for a specific type of algorithm using the tabs below.</h3>

                <Box
                    sx={{
                        flexGrow: 1,
                        maxWidth: { xs: 280, sm: 420, md: 480 },
                        bgcolor: darkmode ? 'rgba(0,0,0,0.45)' : 'rgba(248,248,248,0.6)',
                        color: darkmode ? 'rgba(245,245,245,.9)' : 'rgb(86,89,90)'
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                                color: darkmode ? 'rgba(245,245,245,.9)' : 'rgb(86,89,90)'
                            },
                        }}
                    >
                        {algorithmCategories.map(category => (
                            <Tab style={{ color: darkmode ? 'rgba(245,245,245,.9)' : 'rgb(86,89,90)' }} label={category} onClick={handleActiveCategory} onTouchStart={handleActiveCategory} />
                        ))}
                    </Tabs>

                </Box>
            </div>
            <div className={Cards}>
                {algorithmData.map(algorithm => (
                    algorithm.categories.map(category => (
                        category === activeCategory ?
                            <NavLink to={`/algorithms/${algorithm.title}`} style={{ textDecoration: 'none' }}>
                                <AlgorithmCard
                                    title={algorithm.title}
                                    description={algorithm.description}
                                    Body={Body}
                                    Text={Text}
                                />
                            </NavLink>
                            :
                            <></>
                    ))

                ))}
            </div>


        </div >
    )
}