import * as React from 'react';


export default function Testimonials() {
    return (
        <div>
            <h1>This page is not ready yet. :{'('}</h1>
            <h3>I plan to build a way for people to sign in and allow them to submit live comments on this page.</h3>
        </div>
    );
}