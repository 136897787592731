import { css } from '@emotion/css';
import sizes from '../ScreenSize';

const styles = {
    TextSphere: css`
    position: relative;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    ${sizes.down('sm')} {
       scroll: hidden;
    };
    `
}

export default styles;