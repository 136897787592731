import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import MiniPalette from './MiniPalette';
import styles from './styles/PaletteListStyles';
import { Global, css } from '@emotion/react'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Avatar } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import blue from '@mui/material/colors/blue';
import red from '@mui/material/colors/red';

function PaletteList({ palettes, deletePalette, setColorAppActive }) {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deletingId, setDeletingId] = useState("");
    const openDialog = (id) => {
        setOpenDeleteDialog(true);
        setDeletingId(id);
    }
    const closeDialog = () => {
        setOpenDeleteDialog(false);
        setDeletingId("");
    }
    const handleDelete = () => {
        deletePalette(deletingId);
        closeDialog();
    }
    const revertColorApp = () => {
        setColorAppActive(false);
    }
    return (
        <div className={styles.root}>
            <Global
                styles={css`
                .fade-exit {
                    opacity: 1;
                },
                .fade-exit-active {
                    opacity: 0;
                    transition: opacity 1s ease-out;
                }
            `}
            />
            <div className={styles.container}>

                <nav className={styles.nav}>
                    <NavLink onClick={revertColorApp} to="/">Back to Portfolio</NavLink>
                    <h1 className={styles.heading}>React Colors</h1>
                    <NavLink to="/projects/color-app/palette/new">Create Palette</NavLink>
                </nav>

                <TransitionGroup className={styles.palettes}>
                    {palettes.map(palette => (
                        <CSSTransition key={palette.id} classNames='fade' timeout={1000}>
                            <NavLink key={palette.id} style={{ textDecoration: 'none' }} to={`/projects/color-app/palette/${palette.id}`}>
                                <MiniPalette {...palette}
                                    openDialog={openDialog}
                                    key={palette.id}
                                    id={palette.id}
                                />
                            </NavLink>
                        </CSSTransition>
                    ))
                    }
                </TransitionGroup>
            </div>
            <Dialog
                open={openDeleteDialog}
                aria-labelledby="delete-dialog-title"
                onClose={closeDialog}
            >
                <DialogTitle id="delete-dialog-title">Delete this palette?</DialogTitle>
                <List>
                    <ListItem onClick={handleDelete}>
                        <ListItemButton>
                            <ListItemAvatar>
                                <Avatar style={{ backgroundColor: blue[100], color: blue[600] }}>
                                    <CheckIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>Delete</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={closeDialog}>
                        <ListItemButton>
                            <ListItemAvatar>
                                <Avatar style={{ backgroundColor: red[100], color: red[600] }}>
                                    <CloseIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>Cancel</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Dialog>
        </div >
    )
}

export default PaletteList;