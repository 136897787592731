import { css } from '@emotion/css';

const styles = {
  root: css`
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    padding: 0.5rem;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
    &:hover SVG {
      opacity: 0.9;
      transition: all 0.5s ease-in-out;
    }
   `,
  colors: css`
        background-color: #dae1e3;
        height: 150px;
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
      `,
  title: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -10px;
        margin-bottom: 4px;
        padding-top: 0.5rem;
        color: black;
        font-size: 1rem;
        position: relative;
      `,
  emoji: css`
        margin-right: 0.5rem;
        font-size: 1.5rem;
      `,
  miniColor: css`
        height: 25%;
        width: 20%;
        display: inline-block;
        margin: 0 auto;
        position: relative;
        margin-bottom: -4.5px;
      `,
  deleteIcon: css`
      color: white;
      background-color: #eb3b30;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
      top: 0;
      padding: 6px;
      z-index: 10;
      opacity: 0.01;
      transition: 0.5s !important;
    `
}

export default styles;