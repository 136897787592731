import React, { Component } from 'react';
import NewTodoForm from './NewTodoForm.js';
import Todo from './Todo';
import './TodoList.css';

class TodoList extends Component {
    constructor(props) {
        super(props);
        this.state = { todos: [] };
        this.addTodo = this.addTodo.bind(this);
        this.update = this.update.bind(this);
        this.toggleCompletion = this.toggleCompletion.bind(this);
    }
    addTodo(newTodo) {
        this.setState({
            todos: [...this.state.todos, newTodo]
        })
    }
    remove(id) {
        this.setState({
            todos: this.state.todos.filter(task => task.id !== id)
        });
    }
    // componentDidUpdate(prevProps, prevState) {
    //     console.log("TODOLIST COMPONENT DID UPDATE");
    //     console.log(prevState.todos);
    //     console.log(this.state.todos);
    // }
    update(id, updatedTask) {
        const updatedTodos = this.state.todos.map(todo => {
            if (todo.id === id) {
                return { ...todo, task: updatedTask }
            }
            return todo;
        })
        console.log(updatedTask);
        this.setState({ todos: updatedTodos })
    }
    toggleCompletion(id) {
        const updatedTodos = this.state.todos.map(todo => {
            if (todo.id === id) {
                return { ...todo, completed: !todo.completed }
            }
            return todo;
        })
        this.setState({ todos: updatedTodos })
    }
    render() {
        const todos = this.state.todos.map(todo => (
            <Todo
                task={todo.task}
                key={todo.id}
                id={todo.id}
                completed={todo.completed}
                updateTodo={this.update}
                removeTodo={() => this.remove(todo.id)}
                toggleTodo={this.toggleCompletion}
            />
        ))
        return (
            <div className="TodoList">
                <h1>React Todo List <span>A CRUD Project using ReactJS</span></h1>

                <ul>{todos}</ul>
                <NewTodoForm addTodo={this.addTodo} />
            </div>
        )
    }
}

export default TodoList;