import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Alert, AlertTitle } from '@mui/material';

const ContactForm = ({ Header, HeaderContainer, FormContainer, Form, FormInputLabel, ContactFormInput, ContactFormSubmit }) => {
    const [alertActive, setAlertActive] = useState(false);
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(process.env.REACT_APP_YOUR_SERVICE_ID, process.env.REACT_APP_YOUR_TEMPLATE_ID, form.current, process.env.REACT_APP_YOUR_PUBLIC_KEY)
            .then((result) => {
                e.target.reset();
                setAlertActive(true);
                setTimeout(() => {
                    setAlertActive(false);
                }, 5000)
                console.log(result);

            }, (error) => {
                console.log(error.text);
            });
    };


    return (
        <div className={FormContainer}>
            {alertActive &&
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    Email has been sent! — <strong>I'll be in touch!</strong>
                </Alert>
            }
            <div className={HeaderContainer}>
                <h1>Contact Me! </h1>
            </div>
            <form ref={form} onSubmit={sendEmail} className={Form}>
                <label className={FormInputLabel}>Name</label>
                <input required type="text" name="user_name" placeholder="Your Name" className={ContactFormInput} />
                <label className={FormInputLabel}>Email</label>
                <input required type="email" name="user_email" placeholder="Your Email" className={ContactFormInput} />
                <label className={FormInputLabel}>Message</label>
                <textarea required rows="10" name="message" placeholder="A message you want me to see. :)" className={ContactFormInput} />
                <input type="submit" value="Send" className={ContactFormSubmit} />

            </form>

        </div>
    );
};

export default ContactForm;