import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';




export default function AlgorithmCard({ title, description, Body, Text }) {
    return (
        <Card
            sx={{ maxWidth: 345, minWidth: 300 }}
            style={{ margin: '1.5rem' }}
        >
            <CardContent className={Body}>
                <Typography gutterBottom variant="h5" component="div" className={Text}>
                    {title}
                </Typography>
                <Typography variant="body2" className={Text}>
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
}