import { css } from '@emotion/css';

const styles = {
    Navbar: css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 6vh;
        @media (max-width: 768px) {
            justify-content: space-between;
        }
        `,
    logo: css`
        margin-right: 15px;
        padding: 0 13px;
        font-size: 22px;
        background-color: #ffffff;
        font-family: Roboto;
        height: 100%;
        display: flex;
        align-items: center;
        a {
            text-decoration: none;
            color: black;
            text-transform: lowercase;
        }
        `,
    slider: css` 
        width: 340px;
        margin: 0 10px;
        display: inline-block;
        .rc-slider-rail {
            height: 8px;
        };
        .rc-slider-handle,
        .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging,
        .rc-slider-handle:active,
        .rc-slider-handle:focus,
        .rc-slider-handle:hover {
            background-color: green;
            outline: none;
            border: 2px solid green;
            box-shadow: none;
            width: 13px;
            height: 13px;
            margin-left: -7px;
            margin-top: -3px;
        };
        .rc-slider-track {
            background-color: transparent;
        }
        @media (max-width: 768px) {
            width: 150px;
        }
        `,
    selectContainer: css`
        margin-left: auto;
        margin-right: 1rem;
        @media (max-width: 768px) {
            display: none;
        }
        `
}

export default styles;