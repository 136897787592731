import React, { useState } from 'react';
import { styles, AppBar } from './styles/PaletteFormNavStyles';
import PaletteMetaForm from './PaletteMetaForm';
import { NavLink } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddToPhotos from '@mui/icons-material/AddToPhotos';
import { Button } from '@mui/material';


const { root, navBtns, button, NavContainer, newPaletteHeader } = styles;

function PaletteFormNav({ open, handleDrawerOpen, handleSubmit }) {
    const [formShowing, setFormShowing] = useState(false);
    const [stage, setStage] = useState("form");

    const showForm = () => {
        setFormShowing(true);
    }

    const hideForm = () => {
        setFormShowing(false);
        setStage("form");
    }

    const showEmojiPicker = () => {
        setStage("emoji");
    }

    return (
        <div className={root}>
            <AppBar
                position='fixed'
                open={open}
                color='default'
            >
                <div className={NavContainer}>
                    <Toolbar>
                        <IconButton
                            color='inherit'
                            aria-label='open drawer'
                            onClick={handleDrawerOpen}
                            edge='start'
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <AddToPhotos />
                        </IconButton>
                        <Typography className={newPaletteHeader} variant='h6' noWrap component='div'>
                            Create A Palette
                        </Typography>
                    </Toolbar>
                    <div className={navBtns}>
                        <NavLink to='/projects/color-app' className={button}>
                            <Button variant='outlined' color='primary'>Go Back</Button>
                        </NavLink>
                        <Button variant="contained" onClick={showForm}>Save</Button>
                    </div>
                </div>
            </AppBar>
            {formShowing && (
                <PaletteMetaForm
                    handleSubmit={handleSubmit}
                    className={button}
                    formShowing={formShowing}
                    hideForm={hideForm}
                    stage={stage}
                    setStage={setStage}
                    showEmojiPicker={showEmojiPicker}
                />
            )}
        </div >
    )
}

export default PaletteFormNav;