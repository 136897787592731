import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/css';
import sizes from '../../ScreenSize';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Divider } from '@mui/material';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


function ComicContents({ activeComicId, handleComicActive, darkmode }) {
    const [currComic, setCurrComic] = useState({});
    const [creators, setCreators] = useState([]);
    const [dates, setDates] = useState([]);
    const [id, setId] = useState(0);
    const [thumbnail, setThumbnail] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [urls, setUrls] = useState([])

    const styles = {
        Container: css`
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 20%;
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
            -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
            -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        ${sizes.down('sm')} {
            width: 80vw;
            margin-left: 0;
          }
        div, label, svg, p {
            color: ${darkmode ? 'rgba(225,225,225,.9)' : 'rgb(86,89,90)'};
            border-color: ${darkmode ? 'rgba(225,225,225,.9)' : 'rgb(86,89,90)'};
        }
        `,
        Header: css`
        background-color: ${darkmode ? '#141414' : 'white'};
        span {
            color: ${darkmode ? 'rgba(225,225,225,.9)' : 'rgb(86,89,90)'};
        }
        `,
        CardDetails: css`
        background-color: ${darkmode ? '#141414' : 'white'};
        `,
        Actions: css`
        background-color: ${darkmode ? '#141414' : 'white'};
        `,
        Expanded: css`
        background-color: ${darkmode ? '#141414' : 'white'};
        a {
            color: ${darkmode ? 'rgba(225,225,225,.9)' : '#141414'};
        }
        `
    }

    const { Container, CardDetails, Actions, Expanded, Header } = styles;

    useEffect(() => {
        async function getData() {
            try {
                const res = await axios.get(`https://gateway.marvel.com/v1/public/comics/${activeComicId}?ts=1&apikey=a0fef54382bae1a3a0ab0ceeda7e6a1a&hash=630c87dc0f6a5bacc98416bb7c1f0d85`);
                console.log(res.data.data.results[0]);
                setCurrComic(res.data.data.results[0]);
                setCreators(res.data.data.results[0].creators.items);
                setDates(res.data.data.results[0].dates);
                setId(res.data.data.results[0].id);
                setThumbnail(`${res.data.data.results[0].thumbnail.path}.${res.data.data.results[0].thumbnail.extension}`);
                setUrls(res.data.data.results[0].urls);
                // console.log(res.data.data.results[0]);
            }
            catch (error) {
                if (error.response) {
                    alert(`Error: Status Code ${error.response.status}. ${error.response.statusText}.`)
                } else if (error.request) {
                    alert(`Error: Status Code ${error.request.status}. ${error.request.statusText}`)
                } else {
                    alert('Unknown Error. :(');
                }
            }

        }
        getData();
    }, [activeComicId]) // Anything in the array as the second argument defines the only things we want to cause an update. This prevents infitiely rerendering

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    console.log(thumbnail);
    return (

        <Card className={Container}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="comic" src={thumbnail} />
                }
                action={
                    <IconButton aria-label="settings">
                        <ExitToAppIcon onClick={handleComicActive} />
                    </IconButton>
                }
                className={Header}
                title={currComic.title}
                subheader={`Comic ID: ${id}`}
            />
            <CardMedia
                component="img"
                height="auto"
                image={thumbnail ? `${thumbnail}` : 'https://i.pinimg.com/474x/c1/3f/d3/c13fd3dcdee4c6cb0c9afad78debd898.jpg'}
                alt={currComic.title}
            />
            <CardContent className={CardDetails}>
                <Typography variant="body2" color="text.secondary">
                    {currComic.description ? currComic.description : <Typography>No Description Available</Typography>}
                </Typography>
            </CardContent>
            <CardActions className={Actions} disableSpacing>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse className={Expanded} in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Divider />
                    <h4>Creators:</h4>
                    {creators.length ? creators.map(creator => (
                        <Typography>{creator.name} - {creator.role}</Typography>
                    )) : <Typography>No Creators Listed</Typography>}
                    <div style={{ height: '12px' }}></div>
                    <Divider />
                    <h4>Dates:</h4>
                    {dates.length ? dates.map(date => (
                        <Typography>{date.type}: {date.date}</Typography>
                    )) : <Typography>No Dates Listed</Typography>}
                    <div style={{ height: '12px' }}></div>
                    <Divider />
                    <h4>Links:</h4>
                    {urls.length ? urls.map(link => (
                        <Typography><b>{link.type}</b>: <a href={link.url}>{link.url}</a></Typography>
                    )) : <Typography>No Links Listed</Typography>}
                    <div style={{ height: '12px' }}></div>
                    <Divider />
                    <h2>Data Provided by Marvel. © 2014 Marvel</h2>
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default ComicContents;