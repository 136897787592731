import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'
import { MenuItem, Select, Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import styles from './styles/NavbarStyles';

function Navbar({ level, setLevel, handleChange, showingAllColors }) {

    const [format, setFormat] = useState("hex");
    const [open, setOpen] = useState(false);

    const { Navbar, logo, slider, selectContainer } = styles;

    const handleFormatChange = (e) => {
        setFormat(e.target.value);
        setOpen(true);
        handleChange(e.target.value);
    }

    const closeSnackbar = () => {
        setOpen(false);
    }

    return (
        <header className={Navbar}>
            <div className={logo}>
                <NavLink to="/projects/color-app">ReactColorPalette</NavLink>
            </div>

            {showingAllColors && (
                <div>
                    <span>Level: {level}</span>
                    <div className={slider}>
                        <Slider
                            defaultValue={level}
                            min={100}
                            max={900}
                            step={100}
                            onChange={setLevel}
                        />
                    </div>
                </div>
            )}
            <div className={selectContainer}>
                <Select value={format} onChange={handleFormatChange}>
                    <MenuItem value="hex">HEX - #ffffff</MenuItem>
                    <MenuItem value="rgb">RGB - rgb(255,255,255)</MenuItem>
                    <MenuItem value="rgba">RGBA - rgba(255,255,255, 1.0)</MenuItem>
                </Select>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                open={open}
                onClose={closeSnackbar}
                autoHideDuration={3000}
                message={<span id="message-id">Format Changed to {format.toUpperCase()}</span>}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                action={[
                    <IconButton onClick={closeSnackbar} color="inherit" key="close" aria-label="close">
                        <CloseIcon />
                    </IconButton>
                ]}
            />
        </header>
    );
}

export default Navbar;