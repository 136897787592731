export const lightTheme = {
    body: 'linear-gradient(90deg, rgba(244,244,255,0.5) 0%, rgba(203,208,233,0.4) 50%, rgba(244,244,255,0.5) 100%)',
    text: '#363537',
    toggleBorder: '#FFF',
    background: 'linear-gradient(90deg, rgba(244,244,255,0.5) 0%, rgba(203,208,233,0.4) 50%, rgba(244,244,255,0.5) 100%)',
}
export const darkTheme = {
    body: 'linear-gradient(90deg, rgba(31,31,31,1) 0%, rgba(77,77,81,1) 50%, rgba(31,31,31,1) 100%);',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    background: 'linear-gradient(90deg, rgba(31,31,31,1) 0%, rgba(77,77,81,1) 50%, rgba(31,31,31,1) 100%);',
}