import React, { useState, useEffect } from 'react';
import Comic from './Comic';
import ComicContents from './ComicContents';
import axios from 'axios';
import { css } from '@emotion/css';
import sizes from '../../ScreenSize';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// Images Displayed on Render
import ImageList from '@mui/material/ImageList';
import { TextField, FormControlLabel, Checkbox, Slider, Typography } from '@mui/material';


function Comics({ darkmode }) {

    const styles = {
        Container: css`
            background-color: ${darkmode ? '#141414' : 'rgba(245, 245, 245, 0.9)'};
            color: ${darkmode ? 'rgba(225,225,225,.9)' : 'rgb(86,89,90)'};
            border: 1px solid rgba(0,0,0,0.5);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            padding: 0 24px 24px 24px;
            box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
            -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);

        `,
        Header: css`
            background-color: #EC1D24;
            width: 100%;
            padding: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 12px 12px 0 0;
        `,
        Images: css`
            width: 75vw;
            height: 800px;
        `,
        Form: css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        ${sizes.down('sm')} {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-bottom: 2px solid ${darkmode ? '#efefef' : '#141414'};
          }
        div, label, input, svg, fieldset, p {
            color: ${darkmode ? 'rgba(225,225,225,.9)' : 'rgb(86,89,90)'};
            border-color: ${darkmode ? 'rgba(225,225,225,.9)' : 'rgb(86,89,90)'};
            margin: 2px;
            &:hover input {
                transition: 0.5s ease-in-out;
                background-color: ${darkmode ? 'rgba(45, 45, 50, 0.9)' : 'rgba(230, 230, 230, 0.9)'};
            }
            ${sizes.down('sm')} {
                margin-bottom: 6px;
              }
        }
        label {
            ${sizes.down('sm')} {
                flex-direction: column;
                width: 100%;
              }
        }
        `
    }

    const { Container, Header, Images, Form } = styles;


    const [comics, setComics] = useState([]);
    const [noVariant, setNoVariant] = useState(false);
    const [digital, setDigital] = useState(false);
    const [comicActive, setComicActive] = useState(false);
    const [activeComicId, setActiveComicId] = useState(0);

    const [isValidData, setIsValidData] = useState({
        issueNumber: false,
        titleStartsWith: false,
        startYearRequired: false,
        format: false
    })
    const [config, setConfig] = useState({
        titleStartsWith: '',
        offset: 0,
        limit: 20,
        orderBy: 'title',
        format: '',
        startYear: 2000,
        issueNumber: 0
    })
    const orderByArr = [
        ['title', 'Title ASC'],
        ['focDate', 'FOC Date ASC'],
        ['onsaleDate', 'On Sale Date ASC'],
        ['issueNumber', 'Issue Number ASC'],
        ['modified', 'Modified ASC'],
        ['-title', 'Title DESC'],
        ['-focDate', 'FOC Date DESC'],
        ['-onsaleDate', 'On Sale Date DESC'],
        ['-issueNumber', 'Issue Number DESC'],
        ['-modified', 'Modified DESC']
    ]
    const formatArr = [
        ['comic', 'Comic'],
        ['magazine', 'Magazine'],
        ['trade%20paperback', 'Trade Paperback'],
        ['hardcover', 'Hardcover'],
        ['digest', 'Digest'],
        ['graphic%20novel', 'Graphic Novel'],
        ['digital%20comic', 'Digital Comic'],
        ['infinite%20comic', 'Infinite Comic']
    ]

    useEffect(() => {
        async function getData() {
            try {
                const comicArr = [];
                const issueValidator = isValidData.issueNumber ? `&issueNumber=${config.issueNumber}` : '';
                const titleStartsWithValidator = isValidData.titleStartsWith ? `&titleStartsWith=${config.titleStartsWith}` : '';
                const startYearValidator = isValidData.startYearRequired ? `&startYear=${config.startYear}` : '';
                const formatValidator = isValidData.format ? `&format=${config.format}` : '';
                const validateVariant = noVariant ? `&noVariants=true` : '';
                const validateDigital = digital ? `&hasDigitalIssue=true` : '';

                comicArr.push(`limit=${config.limit}&offset=${config.offset}&orderBy=${config.orderBy}`, startYearValidator, issueValidator, titleStartsWithValidator, formatValidator, validateVariant, validateDigital);

                const comicQuery = comicArr.join('');

                const res = await axios.get(`https://gateway.marvel.com/v1/public/comics?${comicQuery}&ts=1&apikey=a0fef54382bae1a3a0ab0ceeda7e6a1a&hash=630c87dc0f6a5bacc98416bb7c1f0d85`);

                const response = await axios.get(res.data.data.results.length ? `https://gateway.marvel.com/v1/public/comics?${comicQuery}&ts=1&apikey=a0fef54382bae1a3a0ab0ceeda7e6a1a&hash=630c87dc0f6a5bacc98416bb7c1f0d85`
                    :
                    `https://gateway.marvel.com/v1/public/comics?&ts=1&apikey=a0fef54382bae1a3a0ab0ceeda7e6a1a&hash=630c87dc0f6a5bacc98416bb7c1f0d85`);
                setComics(response.data.data.results);
            }
            catch (error) {
                if (error.response) {
                    alert(`Error: Status Code ${error.response.status}. ${error.response.statusText}.`)
                    console.log(error.response);
                } else if (error.request) {
                    alert(`Error: Status Code ${error.request.status}. ${error.request.statusText}`)
                } else {
                    alert('Unknown Error. :(');
                }
            }

        }
        getData();
    }, [config, isValidData, noVariant, digital]) // Anything in the array as the second argument defines the only things we want to cause an update. This prevents infitiely rerendering

    const handleTitleStartsWith = (e) => {
        setConfig({ ...config, titleStartsWith: e.target.value });
        e.target.value ? setIsValidData({ ...isValidData, titleStartsWith: true }) : setIsValidData({ ...isValidData, titleStartsWith: false });
    }

    const handleLimit = (e) => {
        setConfig({ ...config, limit: e.target.value });
    }

    const handleOffset = (e) => {
        setConfig({ ...config, offset: e.target.value });
    }

    const handleformat = (e) => {
        setConfig({ ...config, format: e.target.value });
        e.target.value ? setIsValidData({ ...isValidData, format: true }) : setIsValidData({ ...isValidData, format: false });
    }

    const handleStartYear = (e) => {
        setConfig({ ...config, startYear: e.target.value });
    }

    const handleStartYearRequired = (e) => {
        e.target.checked ? setIsValidData({ ...isValidData, startYearRequired: true }) : setIsValidData({ ...isValidData, startYearRequired: false });
    }

    const handleIssueNumber = (e) => {
        setConfig({ ...config, issueNumber: e.target.value });
        // e.target.value ? setIsIssueNumber(true) : setIsIssueNumber(false);
        e.target.value ? setIsValidData({ ...isValidData, issueNumber: true }) : setIsValidData({ ...isValidData, issueNumber: false });
    }

    const handleOrderBy = (e) => {
        setConfig({ ...config, orderBy: e.target.value })
    }

    const handleNoVariant = (e) => {
        setNoVariant(e.target.checked);
    }

    const handleDigital = (e) => {
        setDigital(e.target.checked);
    }

    const handleComicActive = (e) => {
        e.target.id ? setActiveComicId(e.target.id) : setActiveComicId(0);
        e.target.id ? setComicActive(!comicActive) : setComicActive(false);
    }

    return (
        comicActive ? <ComicContents
            activeComicId={activeComicId}
            handleComicActive={handleComicActive}
            darkmode={darkmode}
        />
            :
            <div className={Container}>
                <div className={Header}>
                    <svg width="130" height="52" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><rect fill="#EC1D24" width="100%" height="100%"></rect><path fill="#FEFEFE" d="M126.222 40.059v7.906H111.58V4h7.885v36.059h6.757zm-62.564-14.5c-.61.294-1.248.44-1.87.442v-14.14h.04c.622-.005 5.264.184 5.264 6.993 0 3.559-1.58 5.804-3.434 6.705zM40.55 34.24l2.183-18.799 2.265 18.799H40.55zm69.655-22.215V4.007H87.879l-3.675 26.779-3.63-26.78h-8.052l.901 7.15c-.928-1.832-4.224-7.15-11.48-7.15-.047-.002-8.06 0-8.06 0l-.031 39.032-5.868-39.031-10.545-.005-6.072 40.44.002-40.435H21.278L17.64 26.724 14.096 4.006H4v43.966h7.95V26.78l3.618 21.192h4.226l3.565-21.192v21.192h15.327l.928-6.762h6.17l.927 6.762 15.047.008h.01v-.008h.02V33.702l1.845-.27 3.817 14.55h7.784l-.002-.01h.022l-5.011-17.048c2.538-1.88 5.406-6.644 4.643-11.203v-.002C74.894 19.777 79.615 48 79.615 48l9.256-.027 6.327-39.85v39.85h15.007v-7.908h-7.124v-10.08h7.124v-8.03h-7.124v-9.931h7.124z"></path><path fill="#EC1D24" d="M0 0h30v52H0z"></path><path fill="#FEFEFE" d="M31.5 48V4H21.291l-3.64 22.735L14.102 4H4v44h8V26.792L15.577 48h4.229l3.568-21.208V48z"></path></svg>
                    <h3 style={{ color: 'rgba(255, 255, 255, 0.9)' }}>Welcome! When adjusting the filters, please be patient as the servers might need a few seconds to make the changes. There is also a chance that your option won't affect the current rendered comics. If this happens, try to be more specific. If no comics are found, I have the default request set as the displayed Comics. You can also click on a comic to view more details about it. Please be mindful of requests as the API Key is limited to 3,000 requests per day. I hope you enjoy! </h3>
                </div>
                <h3>Filters</h3>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 2, width: '33vw' },

                    }}
                    className={Form}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        id="outlined-basic"
                        label="Title Starts With"
                        variant="outlined"
                        helperText="Return only issues in series whose title starts with the input."
                        type="titleStartsWith"
                        onChange={handleTitleStartsWith}
                    />
                    <TextField
                        id="outlined-basic"
                        label="Issue Number"
                        variant="outlined"
                        helperText="Return only issues in series whose issue number matches the input."
                        onChange={handleIssueNumber}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Order By</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={config.orderBy}
                            label="Order By"
                            onChange={handleOrderBy}
                        >
                            {orderByArr.map(orderBy => (
                                <MenuItem value={orderBy[0]}>{orderBy[1]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Format</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={config.format}
                            label="Format"
                            onChange={handleformat}
                        >
                            {formatArr.map(format => (
                                <MenuItem value={format[0]}>{format[1]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Typography id="demo-simple-select-label">Limit</Typography>
                        <Slider
                            size="small"
                            defaultValue={config.limit}
                            aria-label="Small"
                            valueLabelDisplay="auto"
                            min={1}
                            onChange={handleLimit}
                        />
                    </FormControl>
                    <FormControl>
                        <Typography id="demo-simple-select-label">Offset</Typography>
                        <Slider
                            size="small"
                            defaultValue={0}
                            aria-label="Small"
                            valueLabelDisplay="auto"
                            min={0}
                            max={100}
                            onChange={handleOffset}
                        />
                    </FormControl>
                    <FormControl>
                        <Typography id="demo-simple-select-label">Start Year - {config.startYear}</Typography>
                        <Slider
                            size="small"
                            defaultValue={config.startYear}
                            aria-label="Small"
                            valueLabelDisplay="auto"
                            min={1938}
                            max={2023}
                            onChange={handleStartYear}
                        />
                    </FormControl>
                    <FormControlLabel control={<Checkbox
                        aria-label='startYearRequired'
                        checked={isValidData.startYearRequired}
                        onChange={handleStartYearRequired}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    />} label="Require Start Year? This must be checked if you want to specify the year to filter the comics from." />
                    <FormControlLabel control={<Checkbox
                        aria-label='noVariant'
                        checked={noVariant}
                        onChange={handleNoVariant}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    />} label="Variant? Exclude variants (alternate covers, secondary printings, director's cuts, etc.)" />
                    <FormControlLabel control={<Checkbox
                        aria-label='digital'
                        checked={digital}
                        onChange={handleDigital}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    />} label="Digital Issues? Include only results which are available digitally." />
                </Box>

                <ImageList className={Images}>
                    {comics.map((comic, index) => (
                        <Comic
                            comic={comic}
                            index={index}
                            comicActive={comicActive}
                            handleComicActive={handleComicActive}
                            activeComicId={activeComicId}
                        />
                    ))}
                </ImageList>
                <h2>Data Provided by Marvel. © 2014 Marvel</h2>
            </div >
    );
}

export default Comics;
