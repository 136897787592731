import { css } from '@emotion/css';

const styles = {
  root: css`
    height: 25%;
    width: 20%;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    margin-bottom: -3.5px;
    cursor: pointer;
    &:hover svg {
        color: white;
        transition: all 0.3s ease-in-out;
        transform: scale(1.5);
    }
    @media (max-width: 768px) {
        width: 100%;
        height: 20%;
      }
    @media (min-width: 768px) {
        width: 50%;
      }
    @media (min-width: 992px) {
        width: 25%;
      }
    @media (min-width: 1200px) {
        width: 20%;
      }
    `,
  boxContent: css`
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    padding: 10px;
    color: black;
    letter-spacing: 1px;
    line-height: 0px;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    `,
  deleteIcon: css`
    color: rgba(0,0,0,0.5);
    transition: all 0.3s ease-in-out !important;
    font-size: 1.5rem;
    margin-right: 14px;
    `
}

export default styles;