import React, { useState, useEffect } from 'react';
import styles from './styles/ColorPickerForm';
import { Button } from '@mui/material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ChromePicker } from 'react-color';

const { picker, addColor, colorNameInput } = styles;

function ColorPickerForm({ addNewColor, paletteIsFull, colors }) {
    const [currentColor, setCurrentColor] = useState('teal');
    const [newColorName, setNewColorName] = useState('');

    useEffect(() => {
        ValidatorForm.addValidationRule('isColorUnique', (value) =>
            colors.every(
                ({ color }) => color !== currentColor
            )
        );
    })

    const updateCurrentColor = (newColor) => {
        setCurrentColor(newColor.hex);
    };

    const handleChange = (e) => {
        e.preventDefault();
        setNewColorName(e.target.value);
    };

    const handleSubmit = () => {
        const newColor = { color: currentColor, name: newColorName }
        addNewColor(newColor);
        setNewColorName('');
    }

    return (
        <div>
            <ChromePicker
                color={currentColor}
                onChangeComplete={updateCurrentColor}
                className={picker}
            />
            <ValidatorForm onSubmit={handleSubmit} instantValidate={false}>
                <TextValidator
                    // autoFocus
                    value={newColorName}
                    className={colorNameInput}
                    placeholder='Color Name'
                    name='newColorName'
                    variant='filled'
                    margin='normal'
                    onChange={handleChange}
                    validators={['required', 'isColorNameUnique', 'isColorUnique']}
                    errorMessages={['Enter a color name', 'Color name must be unique', 'Color already used']}
                />
                <Button
                    variant='contained'
                    color='primary'
                    style={{ backgroundColor: paletteIsFull ? "grey" : currentColor }}
                    type='submit'
                    disabled={paletteIsFull}
                    className={addColor}
                >
                    {paletteIsFull ? "Full Palette" : "Add Color"}
                </Button>
            </ValidatorForm>
        </div>
    )
}

export default ColorPickerForm;

