function generateId() {
    const num1 = Math.floor(Math.random() * 900) + 100; // 10000 - 99999
    const num2 = Math.floor(Math.random() * 90000) + 10000; // 10000 - 99999
    const num3 = Math.floor(Math.random() * 90000) + 10000; // 10000 - 99999
    const num4 = Math.floor(Math.random() * 900) + 100; // 10000 - 99999
    const num5 = Math.floor(Math.random() * 90000) + 10000; // 10000 - 99999
    const num6 = Math.floor(Math.random() * 9000) + 1000; // 10000 - 99999
    const num7 = Math.floor(Math.random() * 90000) + 10000; // 10000 - 99999
    const num8 = Math.floor(Math.random() * 9000) + 1000; // 10000 - 99999
    const num9 = Math.floor(Math.random() * 90000) + 10000; // 10000 - 99999
    const num10 = Math.floor(Math.random() * 90000) + 10000; // 10000 - 99999
    const alphabet = 'abcdefghijklmnopqrstuvwxyz' // A String with all letters
    const alphaArr = alphabet.split(''); // ['a', 'b', 'c', 'd', etc.] // an array with all letters
    const letter1 = alphaArr[Math.floor(Math.random() * alphaArr.length)]; // a - z
    const letter2 = alphaArr[Math.floor(Math.random() * alphaArr.length)]; // a - z
    const letter3 = alphaArr[Math.floor(Math.random() * alphaArr.length)]; // a - z
    const letter4 = alphaArr[Math.floor(Math.random() * alphaArr.length)]; // a - z
    const letter5 = alphaArr[Math.floor(Math.random() * alphaArr.length)]; // a - z
    const letter6 = alphaArr[Math.floor(Math.random() * alphaArr.length)]; // a - z
    const letter7 = alphaArr[Math.floor(Math.random() * alphaArr.length)]; // a - z
    const letter8 = alphaArr[Math.floor(Math.random() * alphaArr.length)]; // a - z
    const letter9 = alphaArr[Math.floor(Math.random() * alphaArr.length)]; // a - z
    const letter10 = alphaArr[Math.floor(Math.random() * alphaArr.length)]; // a - z
    return `${num1}-${letter6}${num2}${letter1}${letter2}-${num3}${letter7}${letter8}-${num4}${letter3}${num6}-${num5}${letter4}${letter5}${num7}-${num8}${letter9}${letter10}-${num9}${letter5}${letter8}-${num10}${letter3}`
}

export default generateId;