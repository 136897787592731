import React, { useEffect } from "react";
import styles from '../styles/TextSphereStyles';
import "../styles/TextSphere.css";

// Importing TagCloud package
import TagCloud from "TagCloud";



const TextSphere = () => {
  useEffect(() => {
    const container = ".tagcloud";
    const texts = [
      "HTML",
      "CSS",
      "Modern JS",
      "React",
      "EJS",
      "JSX",
      "Algorithms",
      "NextJS",
      "NodeJS",
      "Babel",
      "JSS",
      "Bootstrap",
      "Material UI",
      "GIT",
      "Mongo",
      "Express",
      "AXIOS",
      "MySQL",
      "Data Structures"
    ];

    const options = {
      radius: 175,
      maxSpeed: "normal",
      initSpeed: "normal",
      keep: true,
    };
    TagCloud(container, texts, options);
  }, []);
  return (
    <>
      <div className={styles.TextSphere}>
        {/* span tag className must be "tagcloud"  */}
        <span className="tagcloud"></span>
      </div>
    </>
  );
};

export default TextSphere;
