import React from 'react';
import styles from './styles/PaletteFooterStyles';

function PaletteFooter({ paletteName, emoji }) {

    const { PaletteFooterStyle, EmojiStyle } = styles;
    return (
        <footer className={PaletteFooterStyle}>
            {paletteName}
            <span className={EmojiStyle}>{emoji}</span>
        </footer>
    )
}

export default PaletteFooter;