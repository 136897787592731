import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { generatePalette } from "./colorHelpers";
import ColorBox from './ColorBox';
import Navbar from './Navbar';
import { NavLink } from 'react-router-dom';
import PaletteFooter from './PaletteFooter';
import styles from './styles/PaletteStyles';

function SingleColorPalette({ palettes }) {

    const { Palette, PaletteColors, goBack } = styles;

    const [format, setFormat] = useState("hex");
    function changeFormat(val) {
        setFormat(val);
    }

    function gatherShades(palette, colorToFilterBy) {
        let shades = [];
        const allColors = palette.colors;
        for (let key in allColors) {
            if (key > 50) {
                shades = shades.concat(
                    allColors[key].filter(color => color.id === colorToFilterBy)
                )
            }
        }
        return shades;
    }

    const findPalette = (id) => {
        return palettes.find((palette) => {
            return palette.id === id
        })
    }

    const id = useParams();
    const paletteId = id.paletteId;
    const colorId = id.colorId;
    const palette = generatePalette(findPalette(paletteId));
    const shades = gatherShades(palette, colorId);
    const { paletteName, emoji } = palette;

    const colorBoxes = shades.map(color => (
        <ColorBox
            key={color.name}
            name={color.name}
            background={color[format]}
            showingFullPalette={false} />
    ))
    return (
        <div className={`SingleColorPalette ${Palette}`}>
            <Navbar handleChange={changeFormat} showingAllColors={false} />
            <div className={PaletteColors}>
                {colorBoxes}
                <div className={`${goBack} ColorBox`}>
                    <NavLink to={`/projects/color-app/palette/${paletteId}`}>Go Back</NavLink>
                </div>
            </div>
            <PaletteFooter paletteName={paletteName} emoji={emoji} />
        </div>
    )
}

export default SingleColorPalette;