import TodoList from './TodoList.js';
import './Todo.css';
import './TodoList.css';
import './NewTodoForm.css';

function TodoApp() {
    return (
        <div className="App" style={{ marginBottom: '30%' }}>
            <TodoList />
        </div>
    );
}

export default TodoApp;