import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styles, DrawerHeader, Main } from './styles/NewPaletteFormStyles'
import { DRAWER_WIDTH } from './constants';
import PaletteFormNav from './PaletteFormNav';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material'
import { ValidatorForm } from 'react-material-ui-form-validator';
import DraggableColorBox from './DraggableColorBox';
import ColorPickerForm from './ColorPickerForm';
import seedColors from './seedColors';


function NewPaletteForm({ savePalette, palettes, maxColors }) {
    // const theme = useTheme();
    const navigate = useNavigate();

    const [open, setOpen] = useState(true);
    const [colors, setColors] = useState(seedColors[0].colors);
    const [newColorName, setNewColorName] = useState('');

    useEffect(() => {
        ValidatorForm.addValidationRule('isColorNameUnique', (value) =>
            colors.every(
                ({ name }) => name.toLowerCase() !== value.toLowerCase()
            )
        );
        ValidatorForm.addValidationRule('isPaletteNameUnique', (value) =>
            palettes.every(
                ({ paletteName }) => paletteName.toLowerCase() !== value.toLowerCase()
            )
        );
    })

    const clearColors = () => {
        setColors([]);
    }

    const addRandomColor = () => {
        const allColors = palettes.map(p => p.colors).flat();
        let rand;
        let randomColor;
        let isDuplicateColor = true
        while (isDuplicateColor) {
            rand = Math.floor(Math.random() * allColors.length);
            randomColor = allColors[rand];
            // eslint-disable-next-line no-loop-func
            isDuplicateColor = colors.some(color => color.name === randomColor.name);
        }
        setColors([...colors, randomColor]);
    }

    const addNewColor = (newColor) => {
        setColors([...colors, newColor]);
        setNewColorName("");
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleSubmit = (newPalette) => {
        newPalette.id = newPalette.paletteName.toLowerCase().replace(/ /g, '-')
        newPalette.colors = colors;
        savePalette(newPalette);
        navigate('/projects/color-app')
    }

    const paletteIsFull = colors.length >= maxColors;

    const removeColor = (colorName) => {
        setColors(colors.filter(color => color.name !== colorName))
    }

    const { NewPaletteForm, container, button, buttons } = styles;

    return (
        <Box className={NewPaletteForm}>
            <PaletteFormNav
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleSubmit={handleSubmit}
                drawerWidth={DRAWER_WIDTH}
                palettes={palettes}
            />
            <Drawer
                sx={{
                    width: DRAWER_WIDTH,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: DRAWER_WIDTH,
                        boxSizing: 'border-box',
                    },
                }}
                variant='persistent'
                anchor='left'
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <div className={container}>
                    <Typography variant="h4" gutterBottom>
                        Design Your Palette
                    </Typography>
                    <div className={buttons}>
                        <Button
                            variant="contained"
                            className={button}
                            color="secondary"
                            onClick={clearColors}
                        >
                            Clear Palette
                        </Button>
                        <Button
                            variant="contained"
                            className={button}
                            color="primary"
                            onClick={addRandomColor}
                            disabled={paletteIsFull}
                        >
                            Random Color
                        </Button>
                    </div>
                    <ColorPickerForm
                        addNewColor={addNewColor}
                        paletteIsFull={paletteIsFull}
                        newColorName={newColorName}
                        colors={colors}
                    />
                </div>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                {colors.map(color => (
                    <DraggableColorBox
                        key={color.name}
                        color={color.color}
                        name={color.name}
                        handleClick={() => removeColor(color.name)}
                    />
                ))}
            </Main>
        </Box>
    )
}

export default NewPaletteForm;