import { css } from '@emotion/css';

// Palette List Background was styled from https://colorgradient.dev/gradient-generator

const styles = {
  root: css`
    background-size: 100% 100%;
    background-position: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
    background-image: repeating-linear-gradient(315deg, #00FFFF2E 92%, #073AFF00 100%),repeating-radial-gradient(75% 75% at 238% 218%, #00FFFF12 30%, #073AFF14 39%),radial-gradient(99% 99% at 109% 2%, #FFFFFFFF 26%, #073AFF00 100%),radial-gradient(99% 99% at 21% 78%, #7B00FFFF 0%, #073AFF00 100%);
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: scroll;
   `,
  heading: css`
    font-size: 2.25rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: black;
    `,
  container: css`
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    letter-spacing: 0.0075em;
    @media (max-width: 1100px) {
        width: 80%
      }
    @media (min-width: 1100px) {
        width: 60%
      }
    @media (min-width: 1720px) {
        width: 50%
      }
   `,
  nav: css`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    color: white;
    align-items: center;
    a {
      font-family: "Roboto","Helvetica","Arial",sans-serif;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0.0075em;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: black;
    }
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
   `,
  palettes: css`
    box-sizing: border-box;
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 33.33%);
    grid-gap: 1.5rem;
    margin-bottom: 2rem;
    text-decoration: none;
    @media (max-width: 600px) {
        grid-template-columns: repeat(1, 100%);
        margin-top: 16px;
      }
    @media (min-width: 600px) {
        grid-template-columns: repeat(2, 50%);
      }
    @media (min-width: 1320px) {
        grid-template-columns: repeat(3, 33.33%);
      }
   `
}

export default styles;