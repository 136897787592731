import React, { useState } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import AboutMe from './AboutMe';
import Certifications from './Certifications';
import Projects from './Projects';
import Algorithms from './Algorithms';
import Algorithm from './Algorithm';
import Testimonials from './Testimonials';
import Blogs from './Blogs';
import ContactForm from './ContactForm';
import AppNavigation from './Components/AppNavigation';
import TodoApp from './todo-list/src/TodoApp.js';
import MarvelApp from './marvel-api-app/src/MarvelApp';
import ColorAppProject from './ColorAppProject';
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./styles/globalStyles";
import { lightTheme, darkTheme } from "./Components/Themes"
import { useTheme } from '@mui/material/styles';
import { css } from '@emotion/css';
import sizes from './ScreenSize';
import { Main, DrawerHeader } from './styles/NavDrawerStyles.js'
import Box from '@mui/material/Box';

import ComicContents from './marvel-api-app/src/ComicContents';


export default function NavDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [darkmode, setDarkmode] = useState(true);
  const [colorAppActive, setColorAppActive] = useState(false);
  const [styleTheme, setStyleTheme] = useState('light');
  const [activeCategory, setActiveCategory] = useState('ALL');

  const { title } = useParams();

  const styles = {
    Container: css`
      display: flex;
    `,
    PageContent: css`
        color: ${darkmode ? 'rgba(245,245,245,.9)' : 'rgb(86,89,90)'};
        transition: 0.3s;
        padding-left: 1.5em;
        padding-right: 1.5em;
    `,
    HeaderContainer: css`
    border: ${darkmode ? '2px solid white' : '2px solid black'};
    background: ${darkmode ? 'rgba(0,0,0,0.45)' : 'rgba(248,248,248,0.6)'};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    `,
    Header: css`
        display: flex;
        justify-content: center;
        `
  }

  const CardStyles = {
    CardContainer: css`
    color: ${darkmode ? 'rgba(245,245,245,.9)' : 'rgb(86,89,90)'};
    height: 100%;
    padding-left: 1.5em;
    padding-right: 1.5em;
    ${sizes.down('xs')} {
        font-size: 8px;
        padding-left: 0em;
        padding-right: 0em;
        width: 80vw;
    }
    `,
    CardHeader: css`
    display: flex;
    justify-content: center;
    border: ${darkmode ? '2px solid white' : '2px solid black'};
    background: ${darkmode ? 'rgba(0,0,0,0.45)' : 'rgba(248,248,248,0.6)'};
    padding: 0.8em;
    `,
    CardInfo: css`
    display: flex;
    justify-content: center;
    `,
    Cards: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1em;
    `
  }

  const SingleCardStyles = {
    Body: css`
              background-color: ${darkmode ? 'rgb(30,30,35)' : 'white'};
              min-height: 200px;
              &:hover {
                  background-color: ${darkmode ? 'rgb(40,40,45)' : 'rgb(242,242,245)'};
                  cursor: pointer;
                  transition: 0.3s;
                }
          `,
    Text: css`
              color: ${darkmode ? 'rgba(225,225,225,.9)' : 'rgb(86,89,90)'};
          `
  }

  const FormStyles = {
    FormContainer: css`
    color: ${darkmode ? 'rgba(245,245,245,.9)' : 'rgb(86,89,90)'};
    height: 100%;
    padding-left: 1.5em;
    padding-right: 1.5em;
    ${sizes.down('xs')} {
        font-size: 8px;
        padding-left: 0em;
        padding-right: 0em;
        width: 80vw;
    }
    `,
    Form: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${darkmode ? 'rgba(0,0,0,0.45)' : 'rgba(255,255,255,0.8)'};
    width: 100%;
    `,
    FormInputLabel: css`
    color: ${darkmode ? 'rgba(225,225,225,.9)' : 'rgb(86,89,90)'};
    font-size: 22px;
    letter-spacing: 2px;
    font-weight: bold;
    padding: 0.8em;
    `,
    ContactFormInput: css`
    width: 80%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    `,
    ContactFormSubmit: css`
    background-color: #0fd55b;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 12px;
    transition: 0.5s ease-in-out;
    width: 128px;
    ${sizes.down('xs')} {
      width: 100%;
      margin-bottom: 0px;
      border-radius: 0;
  }
    &:hover {
        background-color: #14f76b;
        transition: 0.5s ease-in-out;
    }

    `
  }

  const { Container, HeaderContainer, Header, PageContent } = styles;
  const { CardContainer, CardHeader, CardInfo, Cards } = CardStyles;
  const { Body, Text } = SingleCardStyles;
  const { FormContainer, Form, FormInputLabel, ContactFormInput, ContactFormSubmit } = FormStyles;


  // Functions

  const handleColorApp = () => {
    setColorAppActive(!colorAppActive);
  }

  const handleActiveCategory = (e) => {
    e.target.innerText ? setActiveCategory(e.target.innerText) : setActiveCategory('ALL')
  }

  return (
    <ThemeProvider theme={darkmode === true ? darkTheme : lightTheme}>
      <>
        <GlobalStyles />
        <div>
          {colorAppActive ? <ColorAppProject />
            :
            <Box className={Container}>
              <AppNavigation
                darkmode={darkmode}
                setDarkmode={setDarkmode}
                theme={theme}
                open={open}
                setOpen={setOpen}
                styleTheme={styleTheme}
                setStyleTheme={setStyleTheme}
              />
              <Main open={open} className={PageContent}>
                <DrawerHeader />

                <Routes>
                  <Route
                    path="/"
                    element={<AboutMe
                      darkmode={darkmode}
                      PageContent={PageContent}
                      HeaderContainer={HeaderContainer}
                      Header={Header}
                      CardContainer={CardContainer}
                      CardInfo={CardInfo}
                      Cards={Cards}
                      Body={Body}
                      Text={Text}
                    />}
                  >
                  </Route>
                  <Route
                    path="/:id"
                    element={<AboutMe
                      darkmode={darkmode}
                      HeaderContainer={HeaderContainer}
                    />}
                  >
                  </Route>
                  <Route
                    path="/certifications"
                    element={<Certifications
                      CardContainer={CardContainer}
                      CardHeader={CardHeader}
                      Cards={Cards}
                      Body={Body}
                      Text={Text}
                    />}
                  >
                  </Route>
                  <Route
                    path="/projects"
                    element={<Projects
                      CardContainer={CardContainer}
                      CardHeader={CardHeader}
                      Cards={Cards}
                      handleColorApp={handleColorApp}
                      Body={Body}
                      Text={Text}
                    />}
                  >
                  </Route>
                  <Route
                    path="/projects/todo-list"
                    element={<TodoApp />}
                  >
                  </Route>
                  <Route
                    path="/projects/marvel-app"
                    element={<MarvelApp darkmode={darkmode} />}
                  >
                  </Route>
                  <Route
                    path="/projects/marvel-app/:id"
                    element={<ComicContents darkmode={darkmode} />}
                  >
                  </Route>
                  <Route
                    path="/algorithms"
                    element={<Algorithms
                      darkmode={darkmode}
                      CardContainer={CardContainer}
                      HeaderContainer={HeaderContainer}
                      CardInfo={CardInfo}
                      Cards={Cards}
                      Body={Body}
                      Text={Text}
                      activeCategory={activeCategory}
                      handleActiveCategory={handleActiveCategory}
                    />}
                  >
                  </Route>
                  <Route
                    path="/algorithms/:title"
                    element={<Algorithm
                      darkmode={darkmode}
                      title={title}
                      activeCategory={activeCategory}
                      handleActiveCategory={handleActiveCategory}
                    />}
                  >
                  </Route>
                  <Route
                    path="/testimonials"
                    element={<Testimonials darkmode={darkmode} />}
                  >
                  </Route>
                  <Route
                    path="/blogs"
                    element={<Blogs darkmode={darkmode} />}
                  >
                  </Route>
                  <Route
                    path="/contact"
                    element={<ContactForm
                      Header={Header}
                      HeaderContainer={HeaderContainer}
                      FormContainer={FormContainer}
                      Form={Form}
                      FormInputLabel={FormInputLabel}
                      ContactFormInput={ContactFormInput}
                      ContactFormSubmit={ContactFormSubmit}
                    />}
                  >
                  </Route>
                </Routes>

              </Main>
            </Box>}
        </div>
      </>
    </ThemeProvider>
  );
}

