import './App.css';
import Comics from './Comics.js'

function MarvelApp({ darkmode }) {
  return (
    <div className="App">
      <Comics darkmode={darkmode} />
    </div>
  );
}

export default MarvelApp;
