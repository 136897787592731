import { styled } from '@mui/material/styles';
import { css } from '@emotion/css';
import { DRAWER_WIDTH } from '../constants';
import MuiAppBar from '@mui/material/AppBar';

const styles = {
    root: css`
    display: flex;
    `,
    navBtns: css`
    display: flex;
    margin: 1rem;
    align-items: center;
    `,
    button: css`
    margin-right: 1rem;
    text-decoration: none;
    `,
    NavContainer: css`
    display: flex;
    justify-content: space-between;
    `,
    newPaletteHeader: css`
    @media (max-width: 768px) {
        display: none;
    }
    `
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: `${DRAWER_WIDTH}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    })
}));

export { styles, AppBar };