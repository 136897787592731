import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { algorithmData } from './data';
import { NavLink } from 'react-router-dom';
import { CopyBlock, dracula, atomOneLight } from "react-code-blocks";
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';

export default function Algorithm({ darkmode, activeCategory, handleActiveCategory }) {
    const [currAlgorithm, setCurrAlgorithm] = useState({});
    const { title } = useParams();

    useEffect(() => {
        const handleCurrAlgorithm = () => {
            setCurrAlgorithm(algorithmData.filter(algorithm => algorithm.title === title).pop());
        }
        if (!currAlgorithm.title) {
            handleCurrAlgorithm()
        }
    }, [currAlgorithm, title])

    const { description, myMethodology, code } = currAlgorithm;

    return (
        <div>
            {currAlgorithm.code ?
                <CopyBlock
                    text={code}
                    language='javascript'
                    showLineNumbers={true}
                    theme={darkmode ? dracula : atomOneLight}
                    wrapLines={true}
                    codeBlock
                />
                :
                <h1>No Code available</h1>
            }
            <Box sx={{ minWidth: 100, backgroundColor: darkmode ? '#141414' : 'white' }}>
                <React.Fragment>
                    <CardContent>
                        <Typography sx={{ fontSize: 22, display: 'flex', justifyContent: 'space-between' }} color={darkmode ? 'rgba(225,225,225,.9)' : '#141414'} gutterBottom>

                            {title}

                            <NavLink to='/algorithms' onClick={handleActiveCategory}>
                                <IconButton aria-label="settings">
                                    <ExitToAppIcon style={{ color: darkmode ? 'rgba(225,225,225,.9)' : '#141414' }} />
                                </IconButton>
                            </NavLink>

                        </Typography>

                        <Typography sx={{ mb: 1.5 }} color={darkmode ? 'rgba(225,225,225,.9)' : '#141414'}>
                            {description}
                        </Typography>
                        <Typography color={darkmode ? 'rgba(225,225,225,.9)' : '#141414'}>
                            {myMethodology}
                            <br />
                        </Typography>
                    </CardContent>

                </React.Fragment>
            </Box>

        </div >
    );
}