import React from 'react';
import Certificate from './Certificate';
import TextSphere from './Components/TextSphere';
import { certificates } from './data';

export default function Certifications({ CardContainer, CardHeader, Cards, Body, Text }) {

    return (
        <div className={CardContainer}>
            <h1 className={CardHeader}>{'<'}Certifications{'>'}</h1>
            <div className={Cards}>
                {certificates.map(cert => (
                    <Certificate
                        title={cert.title}
                        link={cert.link}
                        skills={cert.skills}
                        Body={Body}
                        Text={Text}
                    />
                ))}
            </div>
            <TextSphere />

        </div>
    )
}
