import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ColorBox from "./ColorBox";
import Navbar from './Navbar'
import PaletteFooter from "./PaletteFooter";
import { generatePalette } from "./colorHelpers";
import styles from './styles/PaletteStyles';



function Palette({ palettes }) {
    const { Palette, PaletteColors } = styles;
    const id = useParams();
    const paletteId = id.id;
    const findPalette = (id) => {
        return palettes.find((palette) => {
            return palette.id === id
        })
    }



    const { colors, paletteName, emoji } = generatePalette(findPalette(paletteId));
    const [level, setLevel] = useState(500);
    const [format, setFormat] = useState("hex");
    function changeFormat(val) {
        setFormat(val);
    }

    const colorBoxes = colors[level].map(color => (
        <ColorBox
            background={color[format]}
            name={color.name}
            key={color.id}
            paletteId={paletteId}
            id={color.id}
            showingFullPalette={true}
        />
    ))

    return (
        <div className={Palette}>
            <Navbar
                level={level}
                setLevel={setLevel}
                handleChange={changeFormat}
                showingAllColors={true}
            />
            <div className={PaletteColors}>
                {colorBoxes}
            </div>
            <PaletteFooter paletteName={paletteName} emoji={emoji} />
        </div>
    )
}

export default Palette;