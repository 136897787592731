import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './styles/DraggableColorBoxStyles';
import chroma from "chroma-js";
import { css } from '@emotion/css';

function DraggableColorBox({ color, name, handleClick }) {

    const localStyles = {
        boxContent: css`
        color: ${chroma(color).luminance() <= 0.08 ? "rgba(255,255,255,0.9)" : "rgba(0,0,0,0.6)"};
              `,
    }

    const { root, boxContent, deleteIcon } = styles;

    return (
        <div className={root} style={{ backgroundColor: color }}>
            <div className={`${boxContent} ${localStyles.boxContent}`}>
                <p>{name}</p>
                <span><DeleteIcon
                    className={deleteIcon}
                    onClick={handleClick}
                /></span>
            </div>
        </div>
    )
}

export default DraggableColorBox;