import { css } from '@emotion/css';

const styles = {
    picker: css`
    width: 350px !important; 
    margin-top: 2rem;
    `,
    addColor: css`
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
    font-size: 2rem;
    `,
    colorNameInput: css`
    width: 100%;
    height: 72px;
    `
}

export default styles;