import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import Palette from './colors-app/src/Palette';
import PaletteList from './colors-app/src/PaletteList';
import NewPaletteForm from './colors-app/src/NewPaletteForm';
import SingleColorPalette from './colors-app/src/SingleColorPalette';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Page from './colors-app/src/Page';
import seedColors from './colors-app/src/seedColors';



function ColorAppProject() {
    const [colorAppActive, setColorAppActive] = useState(false);
    const handleColorApp = () => {
        setColorAppActive(!colorAppActive);
    }

    const savedPalettes = JSON.parse(window.localStorage.getItem("palettes"));

    const [palettes, setPalettes] = useState(savedPalettes || seedColors);

    const savePalette = (newPalette) => {
        setPalettes([...palettes, newPalette]);
    }

    const deletePalette = (id) => {
        setPalettes(palettes.filter(palette => palette.id !== id));
    }

    useEffect(() => {
        window.localStorage.setItem("palettes", JSON.stringify(palettes));
    }, [palettes]);

    const location = useLocation();
    return <TransitionGroup>
        <CSSTransition key={location.key} classNames='page' timeout={500}>
            <Routes location={location}>
                <Route path="/projects/color-app"
                    element={
                        <Page>
                            <PaletteList
                                palettes={palettes}
                                deletePalette={deletePalette}
                            />
                        </Page>
                    } />
                <Route path="/projects/color-app/palette/new"
                    element={
                        <Page>
                            <NewPaletteForm
                                palettes={palettes}
                                savePalette={savePalette}
                                maxColors={20}
                            />
                        </Page>
                    } />
                <Route path="/projects/color-app/palette/:id"
                    element={
                        <Page>
                            <Palette
                                palettes={palettes}
                            />
                        </Page>
                    } />
                <Route path="/projects/color-app/palette/:paletteId/:colorId"
                    element={
                        <Page>
                            <SingleColorPalette
                                palettes={palettes}
                            />
                        </Page>
                    } />
                <Route path='/projects/color-app/:id'
                    element={
                        <Page>
                            <PaletteList
                                palettes={palettes}
                                deletePalette={deletePalette}
                                handleColorApp={handleColorApp}
                                setColorAppActive={setColorAppActive}
                            />
                        </Page>
                    } />
            </Routes>
        </CSSTransition>
    </TransitionGroup>
}

export default ColorAppProject;