import { css } from '@emotion/css';

const styles = {
  ColorBox: css`
            width: 20%;
            margin: 0 auto;
            display: inline-block;
            position: relative;
            cursor: pointer;
            margin-bottom: -4.5px;
            &:hover button {
                opacity: 1;
                cursor: pointer;
              };
            @media (max-width: 768px) {
                width: 100%;
                height: 25%;
              }
            @media (min-width: 768px) {
                width: 50%;
              }
            @media (min-width: 1260px) {
                width: 20%;
              }
       `,
  seeMore: css`
            background: rgba(255, 255, 255, 0.3);
            position: absolute;
            border: none;
            right: 0px;
            bottom: 0px;
            width: 60px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            text-transform: uppercase;
          `,
  copyButton: css`
            width: 100px;
            height: 30px;
            position: absolute;
            display: inline-block;
            top: 50%;
            left: 50%;
            margin-left: -50px;
            margin-top: -15px;
            text-align: center;
            outline: none;
            background: rgba(255, 255, 255, 0.3);
            font-size: 1rem;
            line-height: 30px;
            text-transform: uppercase;
            border: none;
            text-decoration: none;
            cursor: pointer;
            opacity: 0;
          `,
  boxContent: css`
            position: absolute;
            width: 100%;
            left: 0px;
            bottom: 0px;
            padding: 10px;
            color: black;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: 12px;
          `,
  copyOverlay: css`
            opacity: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            transition: transform 0.6s ease-in-out;
            transform: scale(0.1);
          `,
  showOverlay: css`
          opacity: 1;
          transform: scale(50);
          z-index: 10;
          position: absolute;
          `,
  copyMsg: css`
          position: fixed;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          font-size: 4rem;
          transform: scale(0.1);
          opacity: 0;
          color: white;
          h1 {
            font-weight: 400;
            text-shadow: 1px 2px black;
            background: rgba(255, 255, 255, 0.2);
            width: 100%;
            text-align: center;
            margin-bottom: 0;
            padding: 1rem;
            text-transform: uppercase;
          };
          p {
            font-size: 2rem;
            font-weight: 100;
          };
          `,
  showMsg: css`
          opacity: 1;
          transform: scale(1);
          z-index: 10;
          transition: all 0.4s ease-in-out;
          transition-delay: 0.3s;
          `
}

export default styles;