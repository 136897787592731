import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';




export default function Project({ title, image, description, handleColorApp, Body, Text }) {
    return (
        <Card
            sx={{ maxWidth: 345, minWidth: 275 }}
            style={{ margin: '1.5rem' }}
            onClick={title === 'Color App' ? handleColorApp : null}
        >
            <CardMedia
                component="img"
                alt="project"
                height="250"
                src={image}
            />
            <CardContent className={Body}>
                <Typography gutterBottom variant="h5" component="div" className={Text}>
                    {title}
                </Typography>
                <Typography variant="body2" className={Text}>
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
}