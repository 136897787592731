import React from "react";
import styles from './styles/MiniPaletteStyles';
import DeleteIcon from '@mui/icons-material/Delete';

function MiniPalette(props) {
  const { colors, emoji, paletteName, id, openDialog } = props;

  const deletePalette = (e) => {
    e.preventDefault();
    openDialog(id);
  }

  const miniColorBoxes = colors.map(color => (
    <div className={styles.miniColor}
      style={{ backgroundColor: color.color }}
      key={color.name}
    />
  ))
  return (
    <div className={styles.root}>
      <DeleteIcon
        className={styles.deleteIcon}
        onClick={deletePalette}
      />
      <div className={styles.colors}>{miniColorBoxes}</div>
      <h5 className={styles.title}>
        {paletteName} <span className={styles.emoji}>{emoji}</span>
      </h5>
    </div>
  )
}

export default MiniPalette;